// common.js ----------------------------
export const ACT_UPLOAD_COMMON = 'actUploadCommon';
export const ACT_GET_MESSAGE_LIST = 'actGetMessageList';
export const ACT_INSERT_MESSAGE = 'actInsertMessage';
export const ACT_UPDATE_READG_MESSAGE = 'actUpdateReadgMessage';
export const ACT_DELETE_MESSAGE = 'actDeleteMessage';
export const ACT_GET_COMMON_CODE_LIST = 'actGetCommonCodeList';
export const ACT_GET_MESSAGE_RECEIVE_COUNT = 'actGetMessageReceiveCount';
export const ACT_UPDATE_COMMON_INQ = 'actUpdateCommonInq';
export const ACT_GET_SHOP_MALL_COMMON_CODE_LIST = 'actGetShopMallCommonCodeList';
export const ACT_SET_MESSAGE_INTERVAL = 'actSetMessageInterval';
export const ACT_GET_SEARCH_LIST = 'actGetSearchList';
export const ACT_GET_PRHB_WRD_LIST = 'actGetPrhbWrdList';
// --------------------------------------------

// auth.js  -----------------------------
export const ACT_LOGIN_USER = 'actLoginUser';
export const ACT_SET_AUTH_TOKEN = 'actSetAuthToken';
export const ACT_REMOVE_AUTH_TOKEN = 'actRemoveAuthToken';
export const ACT_GET_SESSION = 'actGetAuthInfo';
export const ACT_CHECK_SESSION = 'actCheckAuthInfo';
export const ACT_AUTH_PWD_CHECK = 'actAuthPwdCheck'
export const ACT_SIGNUP_USER = 'actSignupUser';
export const ACT_UPLOAD_IDENTITY = 'actUploadIdentity';
export const ACT_CHECK_DUPLICATE_ID = 'actCheckDuplicateId';
export const ACT_UPDATE_PROFILE = 'actUpdateProfile';
export const ACT_GET_CREATOR = 'actGetCreator';
export const ACT_SAVE_CREATOR = 'actSaveCreator';
export const ACT_START_CHECKME = 'actStartCheckMe';
export const ACT_CHECK_FIND_ID = 'actCheckFindId';
export const ACT_CHECK_CHANGE_PASSWD = 'actCheckChnagePassWd';
export const ACT_GET_USER_FIND_ID_LIST = 'actGetUserFindIdList';
export const ACT_GET_USER_FIND_PASSWORD = 'actGetUserFindPassword';
export const ACT_UPDATE_USER_PASSWORD_CHANGE = 'actUpdateUserPasswordChange';
export const ACT_GET_IDENTITY_FILE = 'actGetIdentityFile';
export const ACT_IDENTITY_FILE_DOWNLOAD = 'actIdentityFileDownload';
export const ACT_CHECK_AUTH_RES = 'actCheckAuthValidate';
export const ACT_INSERT_CASHFRIENDS_LOG = 'actInsertCashfriendsLog';
export const ACT_USER_WHDWL = 'actUserWhdwl';
export const ACT_UPDATE_LGN_FAIL_MTRY_YMD = 'actUpdateLgnFailMtry';
export const ACT_CHECK_COMP_INFO = 'actCheckCompInfo';
export const ACT_GET_LMS_LIST = 'actGetLmsList';
export const ACT_UPDATE_USER_RETERMS_AGREE = 'actUpdateUserRetermsAgree';
export const ACT_CHECK_SSO_DUPLICATE_BRNO = 'actCheckSSODuplicateBrno';
export const ACT_AFTER_SSO_LOGIN_USER = 'actAfterSsoLoginUser';
export const ACT_CHECK_SSO_DUPLICATE_ID = 'actCheckSSODuplicateId';
export const ACT_INSERT_SSO_USER_TRANS_MAPPING = 'actInsertSsoUserTransMapping';
export const ACT_INSERT_SSO_USER_TRANS_ADD = 'actInsertSsoUserTransAdd';
export const ACT_CHECK_SSO_VERIFY_PWD = 'actCheckVerifyPassword';
export const ACT_CHECK_SSO_LOGIN_INFO = 'actCheckSsoLoginInfo';
export const ACT_UPDATE_SSO_LGN_FAIL_MTRY_YMD = 'actUpdateSsoLgnFailMtry';
export const ACT_UPDATE_MKT_RCPTN_AGRE_YN = 'actUpdateMktRcptnAgreYn';
export const ACT_UPDATE_OUT_MKT_RCPTN_AGRE_YN = 'actUpdateOutMktRcptnAgreYn';
export const ACT_DRMNCY_SSO_USER_RESTORE = 'actSsoDrmncyUserRestore';
// --------------------------------------------

// main.js  -----------------------------
export const ACT_GET_BANNER_LIST = 'actGetBannerList';
export const ACT_GET_SECTION_GROUP_LIST = 'actGetSectionGroupList';
export const ACT_GET_EDU_BANNER_LIST = 'actGetEduBannerList';
export const ACT_GET_UTIL_SHORT_URL = 'actGetUtilShortUrl';
// --------------------------------------------

// community.js  -----------------------------
export const ACT_GET_SB_COMMUNITY_LIST = 'actGetSbCommunityList';
export const ACT_GET_SB_COMMUNITY = 'actGetSbCommunity';
export const ACT_INSERT_SB_COMMUNITY = 'actInsertSbCommunity';
export const ACT_UPDATE_SB_COMMUNITY = 'actUpdateSbCommunity';
export const ACT_DELETE_SB_COMMUNITY = 'actDeleteSbCommunity';

export const ACT_GET_SB_COMMUNITY_COMMENT_LIST = 'actGetSbCommunityCommentList';
export const ACT_INSERT_SB_COMMUNITY_COMMENT = 'actInsertSbCommunityComment';
export const ACT_DELETE_SB_COMMUNITY_COMMENT = 'actDeleteSbCommunityComment';
export const ACT_UPDATE_SB_COMMUNITY_COMMENT = 'actUpdateSbCommunityComment';

export const ACT_GET_CREATOR_MY_FOOTHOLD = 'actGetCreatorMyFoothold';
export const ACT_GET_CREATOR_FOOTHOLD_LIST = 'actGetCreatorFootholdList';
export const ACT_GET_CREATOR_FOOTHOLD = 'actGetCreatorFoothold';
export const ACT_INSERT_CREATOR_FOOTHOLD = 'actInsertCreatorFoothold';
export const ACT_UPDATE_CREATOR_FOOTHOLD = 'actUpdateCreatorFoothold';

export const ACT_GET_CREATOR_FOOTHOLD_TAG_LIST = 'actUpdateCreatorFootholdTagList';

export const ACT_GET_PLAYGROUND3_LIST = 'actGetPlayground3List';
export const ACT_GET_PLAYGROUND3 = 'actGetPlayground3';

export const ACT_DOWNLOAD_PLAYGROUND2 = 'actDownloadPlayground2';
export const ACT_DOWN_ATTACH_PLAYGROUND2 = 'actDownAttachPlayground2';

export const ACT_DOWNLOAD_PLAYGROUND3 = 'actDownloadPlayground3';
export const ACT_DOWN_ATTACH_PLAYGROUND3 = 'actDownAttachPlayground3';
// --------------------------------------------

// information.js  -----------------------------
export const ACT_GET_PLAYGROUND_LIST = 'actGetPlaygroundList';
export const ACT_GET_PLAYGROUND = 'actGetPlayground';
export const ACT_GET_ISSUE_LIST = 'actGetIssueList';
export const ACT_GET_ISSUE = 'actGetIssue';
export const ACT_GET_INTERVIEW_LIST = 'actGetInterviewList';
export const ACT_GET_INTERVIEW = 'actGetInterview';
// --------------------------------------------

// promotion.js  -----------------------------
export const ACT_GET_CATEGORY_LIST = 'actGetCategoryList';
export const ACT_GET_CAMPAIGN_LIST = 'actGetCampaignList';
export const ACT_GET_CAMPAIGN = 'actGetCampaign';
export const ACT_GET_CAMPAIGN_APPLY_LIST = 'actGetCampaignApplyList';
export const ACT_INSERT_CAMPAIGN_APPLY = 'actInsertCampaignApply';
// --------------------------------------------

// studio.js  -----------------------------
export const ACT_GET_STUDIO_INFO_LIST = 'actGetStudioInfoList';
export const ACT_GET_STUDIO_ROOM = 'actGetStudioRoom';

export const ACT_GET_STUDIO_RESERVATION_LIST = 'actGetStudioReservationList';
export const ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST = 'actGetStudioReservationScheduleList';
export const ACT_INSERT_STUDIO_RESERVATION = 'actInsertStudioReservation';
export const ACT_UPDATE_STUDIO_RESERVATION_CANCEL = 'actUpdateStudioReservationCancel';

export const ACT_GET_STUDIO_REVIEW_LIST = 'actGetStudioReviewList';
export const ACT_GET_STUDIO_REVIEW_COUNT_LIST = 'actGetStudioReviewCountList';
export const ACT_INSERT_STUDIO_REVIEW = 'actInsertStudioReview';

export const ACT_GET_PLAYGROUND2_LIST = 'actGetPlayground2List';
export const ACT_GET_PLAYGROUND2 = 'actGetPlayground2';
// --------------------------------------------

// support.js  -----------------------------
export const ACT_GET_SUPPORT_BUSINESS_LIST = 'actGetSupportBusinessList';
export const ACT_GET_SUPPORT_APPLY_LIST = 'actGetSupportApplyList';
export const ACT_GET_SUPPORT_NOTICE_LIST = 'actGetSupportNoticeList';
export const ACT_GET_SUPPORT_BUSINESS = 'actGetSupportBusiness';
export const ACT_GET_MSS_SPRT_BIZ_LIST = 'actGetMssSprtBizList';
export const ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST = 'actGetMssSprtBizSearchList';
export const ACT_GET_SUPT_BIZ_LIST='actGetSuptBiz';

export const ACT_GET_SPRT_BIZ_TREE_MENU = 'actGetSprtBizTreeMenu';
export const ACT_GET_SPRT_BIZ_INTRD = 'actGetSprtBizIntrd';
export const ACT_GET_SPRT_BIZ_INTRD_EXM_LIST = 'actGetSprtBizIntrdExmList';
export const ACT_GET_SPRT_BIZ_INTRD_EXM = 'actGetSprtBizIntrdExm';
// --------------------------------------------


// commerce.js ----------------------------
export const ACT_CHECK_CMSTR_APLY = 'actCheckCmstrAply'
export const ACT_GET_CMSTR_APLY_STATUS = 'actCmstrAplyStatus'
export const ACT_GET_CTR_APRV_STATUS = 'actCmstrAplyStatus'
export const ACT_INSERT_CMSTR_APLY = 'actInsertCmstrAply'
export const ACT_UPDATE_CMSTR_APLY = 'actUpdateCmstrAply'
export const ACT_INSERT_CMSTR_APLY_DOC = 'actInsertCmstrAplyDoc'
export const ACT_GET_CMSTR_APLY_PDPLOR = 'actGetCmstrAplyPdplor'


export const ACT_CHECK_DUPLICATE_BUSINESSMAN_REG_NO = 'actCheckDuplicateBusinessmanRegNo';
export const ACT_CHECK_DUPLICATE_SCM_ID = 'actCheckDuplicateScmId';
// --------------------------------------------

// survey.js  -----------------------------
export const ACT_GET_SURVEY_LIST = 'actGetSurveyList';
export const ACT_GET_SURVEY_QUESTION_LIST = 'actGetSurveyQuestionList';
export const ACT_INSERT_SURVEY_QUESTION_ANSWER = 'actInsertSurveyQuestionAnswer';
// --------------------------------------------


// education.js  -----------------------------
export const ACT_GET_EDU_COURSE_LIST = 'actGetEduCourseList';
export const ACT_POST_EDU_SSO = 'actPostEduSso';
// -------------------------------------------

// notice1, 2.js  --------------------------------
export const ACT_GET_NOTICE = 'actGetNotice';
export const ACT_GET_NOTICE2 = 'actGetNotice2';
export const ACT_GET_NOTICE2_LIST = 'actGetNotice2List';
export const ACT_DOWNLOAD_NOTICE2 = 'actDownloadNotice2';
export const ACT_DOWN_ATTACH_NOTICE2 = 'actDownAttachNotice2';
// -------------------------------------------

// broadcast.js  -----------------------------
export const ACT_GET_LIVE_VIDEO_LIST = 'actGetLiveVideoList';
export const ACT_GET_LIVE_COMMERCE_LIST = 'actGetLiveCommerceList';
export const ACT_GET_PROG_SMSUSER_LIST = 'actGetProgSmsUserList';
export const ACT_INSERT_PROG_SMSUSER = 'actInsertProgSmsUser';
export const ACT_INSERT_ALL_PROG_SMSUSER = 'actInsertAllProgSmsUser';
export const ACT_DELETE_PROG_SMSUSER = 'actDeleteProgSmsUser';
export const ACT_DELETE_ALL_PROG_SMSUSER = 'actDeleteAllProgSmsUser';
export const ACT_GET_PROG_BANNER_LIST = 'actGetProgBannerList';
export const ACT_GET_BRC_INFO = 'actGetBrcInfo';
export const ACT_GET_PROG_SCHEDULE_LIST = 'actGetProgScheduleList';
export const ACT_INSERT_RECOMM_CNT = 'actInsertRecommCnt';
export const ACT_INSERT_BRC_DCLR = 'actInsertBrcDclr';
// -------------------------------------------

// scommunity.js  -----------------------------
export const ACT_GET_SCOMMUNITY_LIST = 'actGetSCommunityList';
export const ACT_GET_SCOMMUNITY_INFO = 'actGetSCommunityInfo';
export const ACT_GET_SCOMMUNITY_USER_ATBT = 'actGetSCommunityUserAtbt';
export const ACT_INSERT_SCOMMUNITY_USER = 'actInsertSCommunityUser';
export const ACT_GET_SCOMMUNITY_POST_LIST = 'actGetSCommunityPostList';
export const ACT_INSERT_SCOMMUNITY_POST = 'actInsertSCommunityPost';
export const ACT_UPDATE_SCOMMUNITY_POST = 'actUpdateSCommunityPost';
export const ACT_UPDATE_SCOMMUNITY_POST_TOP = 'actUpdateSCommunityPostTop';
export const ACT_DELETE_SCOMMUNITY_POST = 'actDeleteSCommunityPost';
export const ACT_INSERT_SCOMMUNITY_POST_LIK = 'actInsertSCommunityPostLik';
export const ACT_GET_SCOMMUNITY_POST_RPLY_LIST = 'actGetSCommunityPostRplyList';
export const ACT_INSERT_SCOMMUNITY_POST_RPLY = 'actInsertSCommunityPostRply';
export const ACT_INSERT_SCOMMUNITY_POST_RRPLY = 'actInsertSCommunityPostRRply';
export const ACT_UPDATE_SCOMMUNITY_POST_RPLY = 'actUpdateSCommunityPostRply';
export const ACT_DELETE_SCOMMUNITY_POST_RPLY = 'actDeleteSCommunityPostRply';
export const ACT_GET_SCOMMUNITY_BRD_MNT = 'actGetSCommunityBrdMnt';
export const ACT_GET_SCOMMUNITY_BRD_LIST = 'actGetSCommunityBrdList';
export const ACT_GET_SCOMMUNITY_BRD_INFO = 'actGetSCommunityBrdInfo';
export const ACT_GET_SCOMMUNITY_BRD_ATCH = 'actGetSCommunityBrdAtch';
export const ACT_GET_SCOMMUNITY_PRHB_WRDS = 'actGetSCommunityPrhbWrds';
export const ACT_UPLOAD_SCOMMUNITY_POST_IMG = 'actUploadSCommunityPostImgUpload';
// -------------------------------------------

// mypage2.js  -----------------------------
export const ACT_GET_MY_SCOMMUNITY_COUNT = 'actGetMySCommunityCount';
export const ACT_GET_MY_SCOMMUNITY_LIST = 'actGetMySCommunityList';
export const ACT_GET_BIZ_LIST2 = 'actGetBizList2';
export const ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST = 'actGetMssSprtBizHistInfoList';
export const ACT_GET_DHFESTA_MY_CAMPN_LIST = 'actGetMssDhfestaMyCampnList';
export const ACT_GET_DHFESTA_MANPRD_APRV_LIST = 'actGetMssDhfestaManprdAprvList';
export const ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS = 'actUpdateCampnManprdAprvSts';
export const ACT_UPDATE_DHFESTA_CAMPN_MANPRD_EXPSR = 'actUpdateCampnManprdExpsr';
export const ACT_GET_DHFESTA_CAMPN_MANPRO = 'actGetMssDhfestaManprd';
export const ACT_GET_DHFESTA_CAMPN_MANPRO_APRV_HSR_LIST = 'actGetMssDhfestaManprdAprvHsrList';
// -------------------------------------------

// sbptn.js -----------------------------
export const ACT_GET_LIVE_SBPTN = 'actGetLiveSbptn';
export const ACT_SAVE_LIVE_SBPTN = 'actSaveLiveSbptn';
// -------------------------------------------

// vod.js -----------------------------
export const ACT_GET_VOD_CMNT = 'actGetVodCmnt';
export const ACT_GET_VOD_CMNT_REPLY = 'actGetVodCmntReply';

export const ACT_INSERT_VOD_CMNT = 'actInsertVodCmnt';
export const ACT_UPDATE_VOD_CMNT = 'actUpdateVodCmnt';
// export const ACT_DELETE_VOD_CMNT = 'actDeleteVodCmnt';
export const ACT_UPSERT_VOD_CMNT_LK_DSK = 'actUpsertVodCmnt';
// -------------------------------------------

// subsecnmy.js -----------------------------
export const ACT_GET_SUBS_ECNMY_LIST = 'actGetSubsEcnmyList';
export const ACT_UPDATE_SUBS_ECNMY_INQ = 'actUpdateSubsEcnmyInq';
// -------------------------------------------

// privacy.js --------------------------------
export const ACT_GET_PRVC_PRCS_POLIC_LIST = 'actGetPrvcPrcsPolicList';
export const ACT_GET_PRVC_PRCS_POLIC_LATEST = 'actGetPrvcPrcsPolicLatest';
// export const ACT_DOWN_ATTACH_PRVC_PRCS_POLIC = 'actDownAttachPrvcPrcsPolic';
// -------------------------------------------

// join.js------------------------------------
export const ACT_GET_SSO_USER_INFO = 'actGetSsoUserInfo';
// -------------------------------------------

// arecono.js -----------------------------
export const ACT_GET_LOCAL_ECONO_LIST = 'actGetLocalEconoList';
export const ACT_UPDATE_LOCAL_ECONO_INQ = 'actUpdateLocalEconoInq';
// -------------------------------------------

// sentcont.js  -----------------------------
export const ACT_GET_SENTCONT_LIST = 'actGetSentContList';
export const ACT_GET_SENTCONT_NTFCTN_LIST = 'actGetSentContNtfctnList';
export const ACT_GET_SENTCONT_INFO = 'actGetSentContInfo';
// --------------------------------------------

// space.js------------------------------------
export const ACT_GET_SPACE_GRP_LIST = 'actGetSpaceGrpList';
export const ACT_GET_SPACE_LIST = 'actGetSpaceList';
export const ACT_GET_SPACE_INFO = 'actGetSpaceInfo';
export const ACT_GET_SPACE_FAVR = 'actGetSpaceFavr';
export const ACT_UPDATE_SPACE_FAVR = 'actSetSpaceFavr';
export const ACT_GET_SPACE_GLRY_AND_DTL_LIST = 'actGetSpaceGlryAndDtlList';
export const ACT_GET_SPACE_GLRY_DTL_LIST = 'actGetSpaceGlryDtlList';


export const ACT_GET_SPACE_NOTICE_LIST = 'actGetSpaceNoticeList';
export const ACT_GET_SPACE_NOTICE_INFO = 'actGetSpaceNoticeInfo';
export const ACT_GET_SPACE_INQ_LIST = 'actGetSpaceInqList';
export const ACT_DELETE_SPACE_INQ = 'actDeleteSpaceInq';
export const ACT_INSERT_SPACE_INQ = 'actInsertSpaceInq';

export const ACT_GET_SPACE_ROOM_LIST = 'actGetSpaceRoomList';
export const ACT_GET_SPACE_ROOM_INFO = 'actGetSpaceRoomInfo';
export const ACT_GET_SPACE_RSVT_LIST = 'actGetSpaceRsvtList';
export const ACT_UPDATE_SPACE_RSVT_STATUS = 'actUpdateSpaceRsvtStatus';
export const ACT_GET_SPACE_RSVT_SCHD_LIST = 'actGetSpaceReservSchdList';
export const ACT_GET_SPACE_RSVT_CMPY_CHECK = 'actGetSpaceReservCmpyCheck';
export const ACT_INSERT_SPACE_RSVT_INFO = 'actInsertSpaceRsvtInfo';
export const ACT_UPLOAD_SPACE_RSVT_IDENTITY = 'actUploadSpaceRsvtIdentity';
// -------------------------------------------

// diagn.js  -----------------------------
export const ACT_GET_DIAGN_AREA_LIST = 'actGetDiagnAreaList';
export const ACT_GET_MSS_SPRT_BIZ_CMPNY_STATUS_LIST = 'actGetMssSprtBizCmpnyStatusList';
export const ACT_GET_DIAGN_SELF_CHECK = 'actGetDiagnSelfCheck';
export const ACT_GET_DIAGN_HIS_LIST = 'actGetDiagnHisList';
// --------------------------------------------
