import {MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../store/_mut_consts';

export const lengthCheck = resp => {
  return !!(resp && resp.items && resp.items.length > 0);
};

export const collectionCheck = (items, key) => {
  return !!(items && items.length > 0 && items[0][key] > 0);
}

export const getItems = resp => {
  return resp.items;
};

export const getItem = resp => {
  return resp.items[0];
};

export const getCheckItems = resp => {
  if(lengthCheck(resp)){
    return resp.items;
  }
  return [];
}

export const strToHex = str => {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++)
    {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
}

export const hexToStr = hexx => {
  var hex = hexx.toString();
  var str = '';
  for (var i = 0; i < hex.length; i += 2)
  {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

export const isSuccess = resp => {
  return !!(resp && resp.result && resp.result.number === 200);
};

export const getResult = resp => {
  if(resp && resp.result){
    return resp.result;
  }
  return {
    number: 500,
    message: '오류가 발생했습니다. 잠시 후 다시 시도해주세요.'
  };
};

export const getPaging = resp => {
  if(resp && resp.paging){
    return resp.paging;
  }
  return {
    hasMore: false,
    totalCount: 0,
    pageSize: 10,
    pageNo: 1
  }
}

export const ymdToFormat = (ymd, format) => {
  return ymd.substr(0, 4) + format + ymd.substr(4, 2) + format + ymd.substr(6, 2);
}

export const ymdToDate = (ymd) => {
  return new Date(Number(ymd.substr(0, 4)), Number(ymd.substr(4, 2))-1, Number(ymd.substr(6, 2)));
}

export const ymdToDateFormat = (ymd, format) => {
  return dateToDateFormat(ymdToDate(ymd), format);
}

export const hhmmToDateFormat = (hhmm, division) => {
  return hhmm.substr(0, 2) + division + hhmm.substr(2, 2);
}

const week = ['일', '월', '화', '수', '목', '금', '토'];

export const timestampToDateFormat = (timestamp, format) => {
  const d = new Date(timestamp);
  return dateToDateFormat(d, format);
}

export const dateToDateFormat = (d, format) => {
  const yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
        w = (week[d.getDay()]),
        hh = d.getHours(),
        min = ('0' + d.getMinutes()).slice(-2);		// Add leading 0.

  let h = hh, ampm = 'AM';
  let time;
  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh === 0) {
    h = 12;
  }
  if (format === 'yyyy-MM-dd') {
    time = yyyy + '-' + mm + '-' + dd;
  }else if(format === 'yyyy.MM.dd'){
    time = yyyy + '.' + mm + '.' + dd;
  }else if(format === 'yyyyMMdd'){
    time = yyyy + '' + mm + '' + dd;
  }else if(format === 'yyyy-MM-dd(w)'){
    time = `${yyyy}-${mm}-${dd} (${w})`;
  }else if(format === 'yyyy.MM.dd(w)'){
    time = `${yyyy}.${mm}.${dd} (${w})`;
  }else if(format === 'yyyy.MM.dd hh:mm'){
    time = yyyy + '.' + mm + '.' + dd + ' ' + hh + ':' + min;
  }else if(format === 'yyyy-MM-dd, hh:mm'){
    time = yyyy + '-' + mm + '-' + dd + ', ' + hh + ':' + min;
  }else if(format === 'yyyy-MM-dd(w), hh:mm'){
    time = `${yyyy}-${mm}-${dd}(${w}), ${hh}:${min}`;
  }else if(format === 'hh:mm'){
    time = hh + ':' + min;
  }else if(format === 'hhmm'){
    time = hh + '' + min;
  }else if(format === 'hh:mm(A)'){
    time = h + ':' + min + '(' + ampm + ')';
  }else {
    time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
  }
  return time;
}


export const queryToValue = (query, isNum, defaultVal) => {
  if(query){
    return isNum ? parseInt(query) : query;
  }else{
    if(defaultVal !== null && defaultVal !== undefined){
      return defaultVal;
    }else{
      return isNum ? 1 : '';
    }
  }
}

export const itemsToOptions = (items, {nameKey, valueKey}) => {
  return items.map(item => ({
    name: item[nameKey],
    value: item[valueKey]
  }));
}

export const phoneNumberMask = ( phoneNumber ) => {
  if (!phoneNumber) return phoneNumber
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
  let res = ''
  if (phoneNumber.length < 3) {
    res = phoneNumber
  } else {
    if (phoneNumber.substr(0, 2) === '02') {
      if (phoneNumber.length <= 5) {//02-123-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
      } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
      } else if (phoneNumber.length > 9) {//02-1234-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
      }
    } else {
      if (phoneNumber.length < 8) {
        res = phoneNumber
      } else if (phoneNumber.length === 8) {
        res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
      } else if (phoneNumber.length === 9) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length === 10) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length > 10) { //010-1234-5678
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
      }
    }
  }
  return res;
}
/*
  작성자 마스킹 처리(20220831 hib)
  사파리 정규식 오류 https://dantechblog.gatsbyjs.io/posts/til-regex/(참조)
*/
export const writerMask = ( writer ) => {
  if (!writer) return writer
  let res = ''
  res = writer.slice(0,1).concat(writer.replace(writer.slice(0,1),'').replace(/./g,'*'));
  // res = writer.replace(/(?<=.{1})./gi, "*");  //첫번째 1자리를 제외한 마스킹처리
  /* if (writer.length < 2) {
    res = writer.replace(/(?<=.{1})./gi, "*");  //첫번째 1자리를 제외한 마스킹처리
  } else {
    res = writer.replace(/(?<=.{2})./gi, "*");  //두번쨰 2자리를 제외한 마스킹처리
  } */
  return res;
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertToStorageBytes = (x) => {
  let n = parseInt(x, 10) || 0, l = 0;
  while (n > 1024) {
    n = n / 1024;
    l++;
  }
  return (n.toFixed(n >= 10 ? 0 : 1) + ' ' + units[l]);
}

export const paginate = (array, page_number, page_size) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const validate = (store, target, validateObj) => {
  let result = true;
  let msg = null;
  for(let i = 0; i < validateObj.length; i++ ){
    if(validateObj[i].type === 'number'){
      if(target[validateObj[i].key] === null || target[validateObj[i].key] === undefined) {
        msg = validateObj[i].msg;
        result = false;
        break;
      }
    }else{
      if(!target[validateObj[i].key]){
        msg = validateObj[i].msg;
        result = false;
        break;
      }
    }
  }

  if(!result){
    store.commit(`common/${MUT_SHOW_ALERT}`, {
      title: msg
    });
  }

  return result;
}

export const setPaging = (target, res) => {
  const paging = getPaging(res);
  Object.keys(paging).forEach(key => {
    target[key] = paging[key];
  });
}

export const setParams = (params, item) => {
  Object.keys(params).forEach(key => {
    if(item[key] !== null && item[key] !== undefined){
      params[key] = item[key];
    }
  });
}

export const getRegisterTimeFormat = (timestamp, now) => {
  if(!now) now = new Date().getTime();
  const diff = now - timestamp;
  const sec = diff /1000;
  const min = sec / 60;
  const hour = min / 60;
  const day = hour / 24;

  if(day > 30){
    return timestampToDateFormat(timestamp, 'yyyy.MM.dd');
  }else if(hour > 24){
    const day = hour / 24;
    return `${day.toFixed(0)}일전`;
  }else if(hour < 1){
    return `${min.toFixed(0)}분전`;
  }else{
    return `${hour.toFixed(0)}시간전`;
  }
}

//최신글인지 (timestamp형식 등록시간, 현재시간, 기준일자)
export const getIsNew = (timestamp, now, baseDay) => {
  if(!now) now = new Date().getTime();
  const diff = now - timestamp;
  const sec = diff /1000;
  const min = sec / 60;
  const hour = min / 60;
  const day = hour / 24;
  let isNew = false;

  if(!baseDay)
    baseDay = 7;
  if(day <= baseDay){
    isNew = true;
  }else{
    isNew = false;
  }
  return isNew;
}
export const goToTop = (_duration, _adjust) => {
  const targetEle = document.getElementById('gachi');
  if (!targetEle) return;

  // - Get current &amp; target positions
  const scrollEle = document.documentElement || window.scrollingElement,
      currentY = scrollEle.scrollTop,
      targetY = targetEle.offsetTop - (_adjust || 0);
  animateScrollTo(currentY, targetY, _duration);

  // - Animate and scroll to target position
  function animateScrollTo(_startY, _endY, _duration) {
    _duration = _duration ? _duration : 600;
    const unitY = (targetY - currentY) / _duration;
    const startTime = new Date().getTime();
    const endTime = new Date().getTime() + _duration;
    const scrollTo = function() {
      let now = new Date().getTime();
      let passed = now - startTime;
      if (now <= endTime) {
        scrollEle.scrollTop = currentY + (unitY * passed);
        requestAnimationFrame(scrollTo);
      }
    };
    requestAnimationFrame(scrollTo);
  }
}

export const viewTags = (item, key, format) => {
  if(!format) format = ' ';
  const result = [];
  for(let i=1; i<4; i++) {
    if(item[`${key}${i}`]) result.push(`${item[`${key}${i}`]}`);
  }
  return result.join(format);
}

export const numberComma = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getCalendar = (year, month) => {

  const getCalendarData = (year, month) => {
    const currentMonth = parseInt(month);
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const prevYear = prevMonth === 12 ? year - 1 : year;
    const nextYear = nextMonth === 1 ? year + 1 : year;

    const calendar = [[],[],[],[],[],[]];

    const setDate = new Date(year, month - 1, 1);
    //이번 달의 처음 요일을 구합니다.
    const firstDayName = setDate.getDay();
    const lastDay = new Date(targetDay.getFullYear(),targetDay.getMonth() + 1,0).getDate();
    const prevLastDay = new Date(targetDay.getFullYear(),targetDay.getMonth(),0).getDate();
    let startDayCount = 1;
    let lastDayCount = 1;

    //1~6주차를 위해 6번 반복합니다.
    for (let i = 0; i < 6; i++) {
      //일요일~토요일을 위해 7번 반복합니다.
      for (let j = 0; j < 7; j++) {
        // 이번달 이전...
        if (i === 0 && j < firstDayName) {
          calendar[i].push({
            'year': prevYear,
            'month': prevMonth,
            'day': (prevLastDay - (firstDayName - 1) + j)
          });
        }
        else if (i === 0 && j >= firstDayName) {
          calendar[i].push({
            'year': year,
            'month': currentMonth,
            'day': startDayCount
          })
          setFixDayCount(startDayCount++)
        }
        else if (i > 0 && startDayCount <= lastDay) {
          //일요일일 때, 토요일일 때, 나머지 요일 일 때
          calendar[i].push({
            'year': year,
            'month': currentMonth,
            'day': startDayCount
          });
          setFixDayCount(startDayCount++)
        }
        // startDayCount > lastDay: 이번 달의 마지막 날 이후일 때
        // 다음 달임...
        else if (startDayCount > lastDay) {
          // calendar[i].push(lastDayCount++)
          calendar[i].push({
            'year': nextYear,
            'month': nextMonth,
            'day': lastDayCount++
          });
        }
      }
    }
    return calendar;
    //캘린더 div 태그에 내용 붙임
  };

  const setFixDayCount = number => {
    //캘린더 하루마다 아이디를 만들어주기 위해 사용
    let fixNum = "";
    if (number < 10) {
      fixNum = "0" + number;
    } else {
      fixNum = number;
    }
    return fixNum;
  };

  const targetDay = (year > 0 && month > 0) ? new Date(year, (month - 1), 1) : new Date();
  if (targetDay.getMonth() + 1 < 10) {
    return getCalendarData(targetDay.getFullYear(), "0" + (targetDay.getMonth() + 1));
  } else {
    return getCalendarData(targetDay.getFullYear(), "" + (targetDay.getMonth() + 1));
  }
}


export const regex = (text, type) => {
  if(type === 'pwd') {
    const regx = /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{8,16}$/;
    return regx.test(text);

  } else if(type === 'email'){

  }
}

// 첫문자 대문자로 변환
export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const tryResCallback = (tryCount, res, tryCallback, resolve) => {
  if(tryCount > 10) return;

  if(res === undefined){
    tryCount++;
    setTimeout(() => {
      tryCallback();
    }, 300);
  }else{
    resolve(res);
  }
}

export const tagRemove = (tagString) => {
  tagString = tagString?.replace(/<br\/>/ig, "\n");
  tagString = tagString?.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");


  tagString = tagString?.replace(/(<([^>]+)>)/gi, "");
  tagString = tagString?.replace(/&nbsp;/gi,"");
  tagString = tagString?.replace(/&lt;/g,"");
  tagString = tagString?.replace(/&gt;/g,"");
  tagString = tagString?.replace(/&quot;/g,"");
  tagString = tagString?.replace(/&#39;/g,"");
  tagString = tagString?.replace(/\n/g,"");
  return tagString
}

export const getByte = (paramStr) => {
  let byte = 0;
  const strArr = paramStr.split("");
  for (let i in strArr) {
    let cd = strArr[i].charCodeAt(0);
    if (cd >> 7) {  // 128~ 문자
      byte = byte + 2;
    }
    else {
      byte = byte + 1;
    }
  }

  return byte;
}

export const sliceByByte = (str, maxByte) => {
  if (maxByte == null) return str;

  let idx = 0;
  let byte = 0;
  while (idx < str.length && byte < maxByte) {
    const cd = str.charCodeAt(idx);
    byte += cd >> 7 ? 2 : 1;
    idx ++;
  }
  
  return str.substring(0, idx);
}

export const calculateAge = (birthDate) => {
  // 생년월일을 '년', '월', '일'로 분리합니다.
  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  // 현재 날짜를 가져옵니다.
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // 만 나이를 계산합니다.
  let age = currentYear - birthYear;

  // 현재 월과 생일의 월을 비교합니다.
  if (currentMonth < birthMonth) {
    age--;
  }
  // 현재 월과 생일의 월이 같은 경우, 현재 일과 생일의 일을 비교합니다.
  else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }

  return age;
}

export const dateFormatYYYYMM = (dateString) => {
  if (dateString.length !== 8) {
    return dateString
  }

  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);

  return `${year}년 ${month}월`;
}

export const formatBusinessNumber = (dateString) => {
  
  if (!dateString || dateString.length !== 10) {
    return dateString
  }
  return dateString.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
}
